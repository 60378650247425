import React from 'react'
import * as common from '../components/common'
import { useSelector } from 'react-redux'
import {Helmet} from "react-helmet";
import Preloader from '../components/common/Preloader'

const Home = () => {
  const {dynamicdata, maindata} = useSelector((state) => state.masterdata);
  return (
    <>
     
        <Helmet>
            <title>{ Object.keys(maindata).length > 0 && maindata.vendor.domain_title}</title>
            <meta property='description' content={ Object.keys(maindata).length > 0 && maindata.vendor.domain_description} />
            <meta property='keywords' content={ Object.keys(maindata).length > 0 && maindata.vendor.domain_keyword} />
            <meta property='title' content={ Object.keys(maindata).length > 0 && maindata.vendor.domain_title} />
            <meta property="og:title" content={ Object.keys(maindata).length > 0 && maindata.vendor.domain_title} />
            <meta property="og:description" content={ Object.keys(maindata).length > 0 && maindata.vendor.domain_description} />
            <meta property="og:image" content="https://admin.shopersbay.com/assets/uploads/1005/ckeditor/28e4193482fa655385a5a9491be849bb.png" />
            <meta property="og:type" content="website" />
        </Helmet>
        <common.Slider />
        <common.CorporateSection />
        <common.ImageSideContent />
        <common.WeOffer />
        <common.Services />
        <common.Counter />
        {
          (Object.keys(dynamicdata).length > 0 && dynamicdata.postcategorydataList.hasOwnProperty("CaseStudies") && dynamicdata.postcategorydataList.CaseStudies.length > 0)  &&
          <common.CaseStudies data={dynamicdata.postcategorydataList.CaseStudies}/>
        }
        <common.ContactComponent />
   
     
    </>
    
  )
}

export default Home
