import React, {useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { NavLink } from 'react-router-dom';
import logo from '../../images/3.png'
import { FaBars, FaArrowRightLong  } from "react-icons/fa6";
import { IoIosArrowForward } from "react-icons/io";

const MobileNavbar = () => {
  const dispatch = useDispatch();
  const { hastoggle, navdata, showsubmenu } = useSelector(state => state.masterdata);
  const navlist = navdata.filter((ele) => ele.list_lable === "Main Menu")

  useEffect(()=>{
    const addArrows = () => {
      const lis = document.querySelectorAll('.mmenu-wrapper li.menu-item-has-children');
      lis.forEach((li) => {
        if (!li.querySelector('.arrow')) {
          const span = document.createElement('span');
          span.className = 'arrow';
          span.innerHTML = '<i className="ot-flaticon-next"></i>';
          li.insertBefore(span, li.firstChild);
        }
      });
    };

    const toggleSubmenu = () => {
      const arrows = document.querySelectorAll('.mmenu-wrapper .mobile_mainmenu > li span.arrow');
      arrows.forEach((arrow) => {
        arrow.addEventListener('click', function () {
          const ul = this.parentElement.querySelector('> ul');
          if (ul) {
            ul.style.display = (ul.style.display === 'none' || ul.style.display === '') ? 'block' : 'none';
            this.classList.toggle('active');
          }
        });
      });
    };

    addArrows();
    toggleSubmenu();

    // Clean up event listeners on unmount
    return () => {
      const arrows = document.querySelectorAll('.mmenu-wrapper .mobile_mainmenu > li span.arrow');
      arrows.forEach((arrow) => {
        arrow.removeEventListener('click', toggleSubmenu);
      });
    };
  },[navlist ])

  
    
  return (
    <div className="header_mobile coporate-style">
          <div className="container">
            <div className="row">
              <div className="col-5">
                <div className="mobile_logo">
                  <a href="/">
                    <img src={logo} alt="Irax" />
                  </a>
                </div>
              </div>
              <div className="col-7 align-self-center text-right">
                <div className="octf-btn-cta">
                  <div className="octf-menu-mobile octf-cta-header text-left">
                    <div id="mmenu-toggle" className={`mmenu-toggle ${hastoggle && 'active'}`}>
                      <button onClick={ () => {dispatch({
                        type: 'sethastoggle',
                        payload: true,
                      })}}><FaBars className='toggle-bar'/></button>
                    </div>
                    <div className="site-overlay mmenu-overlay"></div>
                    <div id="mmenu-wrapper" className={`mmenu-wrapper on-right ${hastoggle && 'mmenu-open'}`}>
                      <div className="mmenu-inner">
                        <button className="mmenu-close" onClick={ () => {dispatch({
                        type: 'sethastoggle',
                        payload: false,
                      })}}><FaArrowRightLong /></button>
                        <div className="mobile-nav">
                          <ul id="menu-mobile-main-menu" className="mobile_mainmenu none-style">
                            {
                              navlist.length > 0 && navlist.map((ele, i) => {
                                return(
                                  <li key={i} className={`${ele.hasOwnProperty("children") ? "menu-item-has-children" : ""}`}>
                                    {
                                      ele.hasOwnProperty("children") ?

                                      <NavLink onClick={ () => {dispatch({
                                        type: 'setshowsubmenu',
                                        payload: !showsubmenu,
                                      })}}>{ele.name} <IoIosArrowForward /></NavLink>
                                    :
                                    <NavLink onClick={ () => {dispatch({
                                      type: 'sethastoggle',
                                      payload: false,
                                    })}} to={`${ele.hasOwnProperty("children") ? "" : ele.url}`}>{ele.name}</NavLink>
                                    }
                                    
                                    
                                    {
                                      ele.hasOwnProperty("children") &&

                                      <ul className="sub-menu" style={{display: showsubmenu ? 'block' : '',}}>
                                        {ele.children.map((element, ind) => {
                                          return (
                                            <React.Fragment key={ind}>
                                              {
                                                ele.name == "Products" ?
                                                  <li><NavLink onClick={ () => {dispatch({
                                                    type: 'sethastoggle',
                                                    payload: false,
                                                  })}} to={element.url} target="_parent">{element.name}</NavLink></li>
                                                :
                                                  <li><NavLink onClick={ () => {dispatch({
                                                    type: 'sethastoggle',
                                                    payload: false,
                                                  })}} to={element.url}>{element.name}</NavLink></li>

                                              }
                                            </React.Fragment>
                                          )
                                        })}

                                      </ul>
                                    }
                                  </li>
                                )
                              })
                            }
                            
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  )
}

export default MobileNavbar
