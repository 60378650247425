import React from 'react'
import MobileNavbar from './MobileNavbar';
import DesktopNavbar from './DesktopNavbar';


const Header = () => {
  return (
    <>
      <div id="site-header" className="site-header header-static sticky-header">
        <DesktopNavbar />
        

        <MobileNavbar />
      </div>

    </>
  )
}

export default Header