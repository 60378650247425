import React, {useState, useEffect} from 'react'
import { useSelector } from 'react-redux';
import { FaMapMarkerAlt, FaEnvelope, FaPhoneAlt, FaFacebookF, FaLinkedin   } from "react-icons/fa";
import ContactComponent from './ContactComponent';
import {NavLink} from 'react-router-dom'
import logo from '../../images/3.png'
import irax_map from '../../images/contactus_bg.png'
import { useMediaQuery } from 'react-responsive';


const Footer = () => {
  const {maindata, staticPage} = useSelector(state => state.masterdata);
  const isMobile = useMediaQuery({ query: `(max-width: 991px)` });
  const [data, setData] = useState([])

  useEffect(()=>{
    if(staticPage.length > 0){

      const getData = staticPage.filter((ele) => ele.posts_type === 'policies');
      
      if(getData.length > 0){
        setData(getData)
      }

    }
  },[staticPage])

  return (
    <>
    <div className="space-60"></div>
    <div className="space-60 d-none d-md-block"></div>
      <footer id="site-footer" className="site-footer coporate-footer">
        <div className="container">
          <div className='space-60'></div>
          <div className='space-60 d-none d-md-block'></div>
          <div className="row">
            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 mb-5 mb-xl-0">
              <div><NavLink to="/">
                  <img src={logo} alt="" class={`img-fluid ${isMobile ? "w-50" :"w-75"}`}/>
                </NavLink></div>
              <p></p>
              <div className="space-5"></div>
              <div className="space-3"></div>
              <div className="ft-list-icon">
                <a className="facebook" href="https://www.facebook.com/Iraxtechin" target="_blank" rel="noreferrer"><FaFacebookF /></a>
                <a className="linkedin" href="https://www.linkedin.com/company/iraxtechin/" target="_blank" rel="noreferrer"><FaLinkedin /></a>
              </div>
            </div>
            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 mb-5 mb-xl-0">
              <h6 className="widget-footer-title">Head office</h6>
              <div className="footer-contact">
                <div className="footer-contact-icon phone-info-icon">
                  <FaMapMarkerAlt />
                </div>
                <div className="footer-contact-desc phone-info-desc">
                  <h6>Visit Us Daily</h6>
                  {
                    Object.keys(maindata).length > 0 &&
                    <p>{maindata.vendor.domain_address}</p>
                  }

                </div>
              </div>
              <div className="footer-contact">
                <div className="footer-contact-icon phone-info-icon">
                  <NavLink to={`tel:+91${Object.keys(maindata).length > 0 && maindata.vendor.domain_phone}`} target="_blank">
                    <FaPhoneAlt />
                  </NavLink>
                </div>
                <div className="footer-contact-desc phone-info-desc">
                  <h6><NavLink to={`tel:+91${Object.keys(maindata).length > 0 && maindata.vendor.domain_phone}`} target='_blank'>Have Any Questions?</NavLink></h6>
                  <NavLink to={`tel:+91${Object.keys(maindata).length > 0 && maindata.vendor.domain_phone}`} target='_blank'>
                  {
                    Object.keys(maindata).length > 0 &&
                    <p>{maindata.vendor.domain_phone}</p>
                  }
                  </NavLink>
                </div>
              </div>
              <div className="footer-contact">
                <div className="footer-contact-icon phone-info-icon">
                  <NavLink to={`mailto:${Object.keys(maindata).length > 0 && maindata.vendor.domain_email}`} target="_blank">
                    <FaEnvelope />
                  </NavLink>
                </div>
                <div className="footer-contact-desc phone-info-desc">
                  <h6><NavLink to={`mailto:${Object.keys(maindata).length > 0 && maindata.vendor.domain_email}`} target='_blank'>Mail Us</NavLink></h6>
                  <NavLink to={`mailto:${Object.keys(maindata).length > 0 && maindata.vendor.domain_email}`} target='_blank'>
                  {
                    Object.keys(maindata).length > 0 &&
                    <p>{maindata.vendor.domain_email}</p>
                  }
                  </NavLink>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 mb-5 mb-lg-0">
              <h6 className="widget-footer-title">our location</h6>
              <img src={irax_map} alt="" className="img-fluid" />
            </div>
            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-6 align-self-end">
              <div className="footer-locotion">
                <div className="footer-contact">
                  <div className="footer-contact-icon phone-info-icon">
                    <FaMapMarkerAlt />
                  </div>
                  <div className="footer-contact-desc phone-info-desc">
                    <h6>India</h6>
                    <p>Hyderabad</p>
                  </div>
                </div>
                <div className="footer-contact">
                  <div className="footer-contact-icon phone-info-icon">
                    <FaMapMarkerAlt />
                  </div>
                  <div className="footer-contact-desc phone-info-desc">
                    <h6>UAE</h6>
                    <p>Dubai</p>
                  </div>
                </div>
                <div className="footer-contact">
                  <div className="footer-contact-icon phone-info-icon">
                    <FaMapMarkerAlt />
                  </div>
                  <div className="footer-contact-desc phone-info-desc">
                    <h6>USA</h6>
                    <p>Florida</p>
                  </div>
                </div>
                <div className="footer-contact">
                  <div className="footer-contact-icon phone-info-icon">
                    <FaMapMarkerAlt />
                  </div>
                  <div className="footer-contact-desc phone-info-desc">
                    <h6>Germany</h6>
                    <p>Hamburg</p>
                  </div>
                </div>
                
              </div>
            </div>
          </div>

          <div className='space-60'></div>
          <div className='space-60 d-none d-md-block'></div>
          {data.length > 0 &&
            <div className="row">
              <div className="col-md-12 text-center">
                <div className='static-page-list'>
                  { data.map((ele, i)=>{
                    return(
                      <NavLink to={ele.list_key} className="static-page">
                        {ele.title}
                      </NavLink>
                    )
                  })
                    

                  }
                </div>
              </div>
            </div>
          }
          {/* <div className='space-60'></div>
          <div className='space-60 d-none d-md-block'></div> */}
          <div className="row">
            <div className="col-md-12 text-center">
              <p className="copyright-text">{Object.keys(maindata).length > 0 && maindata.vendor.website_copyright}</p>
            </div>
          </div>
        </div>
      </footer>

      <div class="modal fade" id="exampleModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="btn-close" id="close-modal" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body ">
              <ContactComponent />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer
