import React, {useEffect} from 'react'
import { NavLink } from 'react-router-dom'
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FaMapMarkerAlt, FaEnvelope , FaPhoneAlt } from "react-icons/fa";

const LeftSideComponent = () => {
    const { slug } = useParams()
    const {dynamicdata, maindata, navdata} = useSelector((state) => state.masterdata);

    useEffect(()=>{

    },[slug])
  return (
    <>
      <aside className='service-widget service_widget_category'>
        <h6 className='service-widget-title'>Services</h6>
        <ul>
            {
              Object.keys(dynamicdata).length > 0 && dynamicdata.postcategorydataList["Services"].filter((ele) => ele.slug !== slug).map((ele, i)=>{
                  return(
                      <li key={i}><NavLink to={`/services/${ele.slug}` } >{ele.title}</NavLink></li>
                  )
              })  
            }
        </ul>
      </aside>
      <aside className='service-widget service_widget_category'>
      <h6 className='service-widget-title'>Products</h6>
        <ul>
            {
              navdata.length > 0 && navdata.filter((ele) => ele.name === "Products").map(({children})=> children.map((ipo,i) => {
                return(
                  <li key={i}><NavLink to={ipo.url} target="_blank">{ipo.name}</NavLink></li>
              )
              }))
            }
        </ul>
      </aside>
      <aside className='service-widget'>
        <h6 className='service-widget-title'>Contacts</h6>
        <div className='d-flex'>
            <div className='widget-service-icon'>
                <FaMapMarkerAlt />
            </div>
            <div className='widget-service-text'>
                <h6>Visit Us Daily</h6>
                {
                  Object.keys(maindata).length > 0 &&
                  <p>{maindata.vendor.domain_address}</p>
                }
            </div>
        </div>
        <div class="space-10"></div>
        <div class="space-6"></div>
        <div className='d-flex'>
            <div className='widget-service-icon'>
                <a href={`tel:+91${Object.keys(maindata).length > 0 && maindata.vendor.domain_phone}`}>
                  <FaPhoneAlt />
                </a>
            </div>
            <div className='widget-service-text'>
                <h6>Have Any Questions?</h6>
                {
                  Object.keys(maindata).length > 0 &&
                  <a href={`tel:+91${Object.keys(maindata).length > 0 && maindata.vendor.domain_phone}`}><p>{maindata.vendor.domain_phone}</p></a>
                }
            </div>
        </div>
        <div class="space-10"></div>
        <div class="space-6"></div>
        <div className='d-flex'>
            <div className='widget-service-icon'>
                <a href={`mailto:${Object.keys(maindata).length > 0 && maindata.vendor.domain_email}`}>
                  <FaEnvelope />
                </a>
            </div>
            <div className='widget-service-text'>
                <h6>Mail Us</h6>
                {
                  Object.keys(maindata).length > 0 &&
                  <p>{maindata.vendor.domain_email}</p>
                }
            </div>
        </div>
        <div class="space-10"></div>
        <div class="space-6"></div>
      </aside>
    </>
  )
}

export default LeftSideComponent
