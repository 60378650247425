import React, {useState, useEffect} from 'react'
import * as common from '../components/common'
import {useParams} from 'react-router-dom'
import { useSelector } from 'react-redux'

const SeviceDetail = () => {
    const { slug } = useParams()
    const [data, setData] = useState([])
	  const dynamicdata = useSelector((state) => state.masterdata.dynamicdata);

    useEffect(()=>{
      if(Object.keys(dynamicdata).length > 0){

        const getData = dynamicdata.postcategorydataList["Services"].filter((ele) => ele.slug === slug);
        
        if(getData.length > 0){
          setData(getData)
        }

      }
    },[slug,dynamicdata])

  return (
    <>
    {
      data.length > 0  ?
        <>
          <common.ServiceSlider data={data[0]}/>
          <common.ServiceDetailComponent data={data[0]}/>
          <common.ContactComponent />
        </>
      :
      <common.Preloader />
    
    }
      
      
    </>
  )
}

export default SeviceDetail
