const initialState = {
    navdata:[],
    maindata:{},
    dynamicdata:{},
    hastoggle:false,
    showsubmenu:false,
    loader: false,
    staticPage:[],
    displaysubmenu: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case 'setnavdata':
            return {
            ...state,
            navdata: action.payload
            }; 
        case 'setmaindata':
            return {
            ...state,
            maindata: action.payload
            };
        case 'sethastoggle':
            return {
            ...state,
            hastoggle: action.payload
            };
        case 'setshowsubmenu':
            return {
            ...state,
            showsubmenu: action.payload
            };
        case 'setdynamicdata':
            return {
            ...state,
            dynamicdata: action.payload
            };
        case 'setloader':
            return {
                ...state,
                loader: action.payload
            };
        case 'setstaticPage':
            return {
                ...state,
                staticPage: action.payload
            };
        case 'setdisplaysubmenu':
            return {
                ...state,
                displaysubmenu: action.payload
            };

            
       
        

            
        default:
        return state;
    }
};
