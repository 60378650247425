import React, {useState, useEffect} from 'react'
import { useSelector } from 'react-redux'

import Preloader from '../components/common/Preloader'
import * as common from '../components/common'

const AboutUs = () => {
  const [data, setData] = useState({})
  const dynamicdata = useSelector((state) => state.masterdata.dynamicdata);
  
  useEffect(()=>{
		if(Object.keys(dynamicdata).length > 0){
			setData(dynamicdata.postcategorydataList["About"][0])
		}
	},[dynamicdata])

  

  return (
    <>
    
      {Object.keys(data).length > 0 ?
        <>
          <common.ServiceSlider data={data} />
          <div className='about-us-section' >
            {
              <div dangerouslySetInnerHTML={{ __html: data.body }}></div>
            }
          </div>
          <common.ContactComponent />
        </>
        : 
        <common.Preloader />
        
      }
   
    </>
  )
}

export default AboutUs
