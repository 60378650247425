import React, {useState, useEffect} from 'react'
import {useParams} from 'react-router-dom'
import { useSelector } from 'react-redux'
import * as common from '../components/common'


const CaseStudiesDetail = () => {
    const { slug } = useParams()
    const [data, setData] = useState([])
    const dynamicdata = useSelector((state) => state.masterdata.dynamicdata);

    useEffect(()=>{
        if(Object.keys(dynamicdata).length > 0){
  
          const getData = dynamicdata.postcategorydataList["CaseStudies"].filter((ele) => ele.slug === slug);
          
          if(getData.length > 0){
            setData(getData)
          }
  
        }
      },[slug,dynamicdata])


  return (

    <>
        {
      data.length > 0  ?
        <>
        <div className="entry-content">
            <div className="container">
                <div className="row">
                    <div className="content-area col-lg-9 col-md-12 col-sm-12 col-xs-12 mb-5 mb-lg-0">
                        <common.CaseDetailComponent data={data[0]} />
                        
                    </div>
                    <div className="widget-area primary-sidebar col-lg-3 col-md-12 col-sm-12 col-xs-12">
                        <common.LeftSideCase data={data[0]} />
                       
                    </div>
                </div>
            </div>
        </div>
        <common.ContactComponent />
        </>
        :
        <common.Preloader />
        }
    </>
  )
}

export default CaseStudiesDetail