import React, { useEffect, useState } from 'react'
import $ from 'jquery';


const Preloader = ({ children }) => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        window.jQuery = window.$ = $;

        const Royal_Preloader = {
            config: function (options) {
                const preloaderHTML = `
                    <div id="preloader" style="background: ${options.background}; color: ${options.text_colour}; position: fixed; top: 0; left: 0; width: 100%; height: 100%; display: flex; align-items: center; justify-content: center; flex-direction: column; z-index: 9999;">
                        <img src="${options.logo}" alt="logo" style="width: ${options.logo_size[0]}px; height: ${options.logo_size[1]}px;" />
                        <div id="percentage">${options.showPercentage ? '0%' : ''}</div>
                    </div>
                `;
                $('body').prepend(preloaderHTML);

                let percentage = 0;
                const interval = setInterval(() => {
                    percentage += 10;
                    $('#percentage').text(`${percentage}%`);
                    if (percentage >= 100) {
                        clearInterval(interval);
                        $('#preloader').fadeOut(() => {
                            $('#preloader').remove();
                            setLoading(false);
                        });
                    }
                }, 100);
            }
        };

        Royal_Preloader.config({
            mode: 'logo',
            logo: 'https://admin.shopersbay.com/assets/uploads/10015/ckeditor/119b88785953b632f17f9e1f1ab26c6b.png',
            logo_size: [160, 75],
            showProgress: true,
            showPercentage: true,
            text_colour: '#000000',
            background: '#ffffff'
        });
    }, []);

    return loading ? null : <>{children}</>;
};

export default Preloader;
