import React, { Suspense} from 'react'
import { Route, BrowserRouter as Router, Routes, Navigate} from 'react-router-dom';
import { ScrollTop } from '../hooks';
import DesktopLayout from '../components/layout/DesktopLayout';
import * as view from '../view'
import * as ROUTES from '../constants/routes';

const AppRouter = () => {
  return (
    <Router>
      <>
        <Suspense>
          <Routes>
            <Route path='*' element={<Navigate to='/' />} />
            <Route
              element=
              { 
                <ScrollTop>
                  <DesktopLayout>
                      <view.Home />
                  </DesktopLayout>
                </ScrollTop>
              }
              exact
              path={ROUTES.HOME}
            />
            <Route
              element=
              { 
                <ScrollTop>
                  <DesktopLayout>
                      <view.ContactUs />
                  </DesktopLayout>
                </ScrollTop>
              }
              exact
              path={ROUTES.CONTACT}
            />
            <Route
              element=
              { 
                <ScrollTop>
                  <DesktopLayout>
                      <view.AboutUs />
                  </DesktopLayout>
                </ScrollTop>
              }
              exact
              path={ROUTES.ABOUT}
            />
            <Route
              element=
              { 
                <ScrollTop>
                  <DesktopLayout>
                      <view.SeviceDetail />
                  </DesktopLayout>
                </ScrollTop>
              }
              exact
              path={ROUTES.SERVICEDETAIL}
            />
            <Route
              element=
              { 
                <ScrollTop>
                  <DesktopLayout>
                      <view.CaseStudiesDetail />
                  </DesktopLayout>
                </ScrollTop>
              }
              exact
              path={ROUTES.CASEDETAIL}
            />
          </Routes>
        </Suspense>
      </>
    </Router>
  )
}

export default AppRouter
