import React from 'react'
import { GrMoney, GrTechnology } from "react-icons/gr";
import { AiFillProduct } from "react-icons/ai";

const CorporateSection = () => {
    return (
        <div className="coporate-ibox-section">
            <div className="container-fluid">
                <div className="space-30"></div>
                <div className="row">
                    <div className="col-xl-4 col-md-6 mb-5 mb-xl-0">
                        <div className="box-s1 icon-left coporate-icon-box">
                            <div className="icon-box icon-box-2">
                                <div className="icon-main">
                                    <GrMoney color="#55bb53"/>
                                </div>
                                <div className="content-box">
                                    <h5 className="title-box"><a href="#">IT Consulting</a></h5>
                                    <p>We offer IT consulting services, including technology strategy, system integration and  optimization.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-6 mb-5 mb-xl-0">
                        <div className="box-s1 icon-left coporate-icon-box">
                            <div className="icon-box icon-box-2">
                                <div className="icon-main">
                                    <GrTechnology color="#55bb53"/>
                                </div>
                                <div className="content-box">
                                    <h5 className="title-box"><a href="#">Software Services</a></h5>
                                    <p>Focused on innovation, we serve sectors including Retail, Real Estate, Healthcare and more.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-6 mb-5 mb-md-0">
                        <div className="box-s1 icon-left coporate-icon-box">
                            <div className="icon-box icon-box-2">
                                <div className="icon-main">
                                    <AiFillProduct color="#55bb53"/>
                                </div>
                                <div className="content-box">
                                    <h5 className="title-box"><a href="#">IT Products</a></h5>
                                    <p>We offer products and services, including hardware & software to enhance business efficiency and operations.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CorporateSection
