import React, {useEffect, useState} from 'react'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
// import required modules
import { Autoplay, Pagination } from 'swiper/modules';

import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import img1 from '../../images/blog1.jpg';
import img2 from '../../images/gallery1.jpg';
import img3 from '../../images/gallery2.jpg';


const CaseStudies = ({data}) => {
    const {dynamicdata, maindata} = useSelector((state) => state.masterdata);
    console.log("maindata", dynamicdata)
    
    const [datalist, setdatalist] = useState([])


    // const slideData = [
    //     {
    //         "id": 1,
    //         "name": "IT Consultation",
    //         "type": "startup",
    //         "image": "https://admin.shopersbay.com/assets/uploads/10015/ckeditor/3822f168919f1889a5641b98b7c1e7d8.jpg"
    //     },
    //     {
    //         "id": 2,
    //         "name": "Digital Marketing",
    //         "type": "startup",
    //         "image": "https://admin.shopersbay.com/assets/uploads/10015/ckeditor/5fa1c15abc9142efa6be3415abb66b73.jpg"
    //     },
    //     {
    //         "id": 3,
    //         "name": "Web Application",
    //         "type": "startup",
    //         "image": "https://admin.shopersbay.com/assets/uploads/10015/ckeditor/3822f168919f1889a5641b98b7c1e7d8.jpg"
    //     },
    //     {
    //         "id": 4,
    //         "name": "Mobile Application",
    //         "type": "startup",
    //         "image": "https://admin.shopersbay.com/assets/uploads/10015/ckeditor/5fa1c15abc9142efa6be3415abb66b73.jpg"
    //     }
    // ]

    useEffect(()=>{
        setdatalist(data)
    },[data])

    console.log("data", data)

    return (
        <>
        <div className="coporate-portfolio">
            <div className="space-60"></div>
            <div className="space-30"></div>
            <div className="space-5"></div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 offset-lg-2 text-center">
                        <div className="ot-heading">
                            <span className="pl-0">Case Studies</span>
                            <h3 className="main-head">All software development case studies</h3>
                        </div>
                        <div className="space-20"></div>
                        <p className="px-xl-5">Below you will find our available (not confidential) software development case studies defining the solutions implemented by Irax. Our services include mobile & web app development, digital product design, cloud solutions, and more.</p>
                        <div className="space-30"></div>
                    </div>
                </div>
            </div>
            <div className="container-fluid px-0">
                <div className="project-slider img-scale">
                   <Swiper
                    slidesPerView={'auto'}
                    spaceBetween={60}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    loop={true}
                    centeredSlides={true}
                    pagination={{
                    clickable: true,
                    
                    }}
                    freeMode={true}
                    modules={[Autoplay,Pagination]}
                   
                    className="mySwiper"
                   
                   >
                    {
                        datalist.length > 0 && datalist.map((ele, i)=>
                            {
                                return(
                                    <SwiperSlide>
                                        <div className="project-items">
                                            <NavLink to={`/case-studies/${ele.slug}`}>
                                                <div className="projects-box">
                                                    <div className="projects-thumbnail">
                                                        <img src={`${Object.keys(maindata).length > 0 && maindata.vendor.cdn_domain}${ele.image}`} alt="" />
                                                    </div>
                                                    <div className="portfolio-info">
                                                        <div className="portfolio-info-inner">
                                                            <h5>{ele.title}</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </NavLink>
                                        </div>
                                    </SwiperSlide>
                                )
                            })
                    }
                       
                   </Swiper>
                </div>
            </div>
            <div className="space-60"></div>
            <div className="space-30"></div>
            <div className="space-5"></div>
        </div>
        </>
    )
}

export default CaseStudies
