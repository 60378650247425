import React, {useEffect} from 'react'
import { NavLink } from 'react-router-dom'
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';


const LeftSideCase = ({data}) => {
    const { slug } = useParams()
    const {dynamicdata, maindata} = useSelector((state) => state.masterdata);

    useEffect(()=>{

    },[slug])

  return (
    <>
      <aside className="widget widget_recent_news">
        <h6 className="widget-title">Case Studies</h6>
        <ul className="recent-news clearfix">
            {
              Object.keys(dynamicdata).length > 0 && dynamicdata.postcategorydataList["CaseStudies"].filter((ele) => ele.slug !== slug).map((ele, i)=>{
                  return(
                    <li className="clearfix" key={i}> 
                        <NavLink to={`/case-studies/${ele.slug}` } >
                            <div className="thumb">
                                <img src={`${Object.keys(maindata).length > 0 && maindata.vendor.cdn_domain}${ele.image}`} alt="" />
                            </div>
                            <div className="entry-header">
                                <h6>{ele.title}</h6>
                            </div>
                        </NavLink>
                    </li>
                  )
              })  
            }
            
        </ul>
    </aside>
    </>
  )
}

export default LeftSideCase
