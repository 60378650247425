import React from 'react'
import { FaWhatsapp} from "react-icons/fa";
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

const Slider = () => {
    const maindata = useSelector(state => state.masterdata.maindata);

  return (
    <div className="coporate-banner">
        <div className="container">
            <div className="space-70"></div>
            <div className="row">
                <div className="col-lg-6 pr-xl-0">
                    <span className="sub-title"><span>Irax Tech Private Limited</span></span>
                    <h2>Your Technology Partner for <span className="hl-text">Software Innovation & Market Leading</span> Solutions</h2>
                    <div className="ot-div">
                        <div class="ot-button">
                            <a href="#" class="octf-btn octf-btn-main" data-bs-target="#exampleModal" data-bs-toggle="modal">Get In Touch </a>
                        </div>
                        <div className="box-s1 icon-left bline-no">
                            <div className="icon-box icon-box-2">
                                <div className="icon-main">
                                    <NavLink to={`https://wa.me/${maindata.hasOwnProperty('vendor') && maindata.vendor.domain_whatsapp}`} target="_blank">
                                        <FaWhatsapp />
                                    </NavLink>
                                </div>
                                <div className="content-box">
                                    <h5 className="title-box"><NavLink to={`https://wa.me/${maindata.hasOwnProperty('vendor') && maindata.vendor.domain_whatsapp}`} target="_blank">Have Any Questions?</NavLink></h5>
                                    <p><NavLink to={`https://wa.me/${maindata.hasOwnProperty('vendor') && maindata.vendor.domain_whatsapp}`} target="_blank">{maindata.hasOwnProperty('vendor') && maindata.vendor.domain_whatsapp}</NavLink></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="space-120"></div>
        </div>
    </div>
  )
}

export default Slider
