import React from 'react'
import { useSelector } from 'react-redux'

const WeOffer = () => {
    
    return (
        <>
        <div className="consulting-priorities">
            <div className="container">
                <div className="space-30"></div>
                <div className="row">
                    <div className="col-lg-7 offset-lg-5">
                        <div className="consulting-priorities-desc">
                            <div className="ot-heading">
                                <span className="is_line">Main Principles</span>
                                <h3 className="main-head">Guiding Principles for Exceptional Solutions</h3>
                            </div>
                            <div className="space-20"></div>
                            <div className="space-2"></div>
                            <div className="icon-list d-flex">
                                <div className="icon-main">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 515.556 515.556"><path d="m0 274.226 176.549 176.886 339.007-338.672-48.67-47.997-290.337 290-128.553-128.552z"></path></svg>
                                </div>
                                <div className="content-box">
                                    <h6 className="title-box">Innovation and Creativity</h6>
                                    <p>Foster a culture of innovation, encouraging creative problem-solving and continuous improvement in products and processes.</p>
                                </div>
                            </div>
                            <div className="space-10"></div>
                            <div className="icon-list d-flex">
                                <div className="icon-main">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 515.556 515.556"><path d="m0 274.226 176.549 176.886 339.007-338.672-48.67-47.997-290.337 290-128.553-128.552z"></path></svg>
                                </div>
                                <div className="content-box">
                                    <h6 className="title-box">Agile Methodology</h6>
                                    <p>Implement agile development practices to enable flexibility, quick iterations, and responsiveness to changing requirements.</p>
                                </div>
                            </div>
                            <div className="space-10"></div>
                            <div className="icon-list d-flex">
                                <div className="icon-main">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 515.556 515.556"><path d="m0 274.226 176.549 176.886 339.007-338.672-48.67-47.997-290.337 290-128.553-128.552z"></path></svg>
                                </div>
                                <div className="content-box">
                                    <h6 className="title-box">Scalability and Performance</h6>
                                    <p>Design software solutions that are scalable and perform well under varying loads to meet the demands of growing user bases.</p>
                                </div>
                            </div>
                            <div className="space-10"></div>
                            <div className="icon-list d-flex">
                                <div className="icon-main">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 515.556 515.556"><path d="m0 274.226 176.549 176.886 339.007-338.672-48.67-47.997-290.337 290-128.553-128.552z"></path></svg>
                                </div>
                                <div className="content-box">
                                    <h6 className="title-box">Commitment to Excellence</h6>
                                    <p className="mb-0">Strive for excellence in all aspects of the business, from development to customer service, ensuring high-quality deliverables.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="space-30"></div>
            </div>
        </div>
        <div className="space-60"></div>
        <div className="space-60 d-none d-md-block"></div>
        </>
    )
}

export default WeOffer
