import axios from 'axios';
import { useSelector } from 'react-redux';



const Apis = () => {
    const maindata = useSelector(state => state.masterdata.maindata);
    axios.defaults.baseURL = Object.keys(maindata).length > 0 ? maindata.vendor.cdn_domain : "https://admin.shopersbay.com/";
    axios.defaults.headers.common['authentication'] = "hk7ei9zazwf93uvhqcod65uyfpdu50fl";
    // axios.defaults.headers.common['authentication'] = "6502552bd2e8d0e0a760b04611fc3ec8";

    const getMasterData = async () => {
        const response = await axios.get("asapi/getMasterData")
        return response;
    };

    const getDynamicData = async () => {
        const response = await axios.get("asapi/getHomePageNewsData")
        return response;
    };

    const getNavigationlist = async () => {
        const response = await axios.get("asapi/getNavigationList")
        return response;
    };

    const saveContactFormdata = async (input_data) => {
        const response = await axios.post("asapi/saveContact", input_data);
        return response;
    }

    const getStaticPage = async () => {
        const response = await axios.get("asapi/getStaticPageList");
        return response;
    }
    return {
        getNavigationlist : getNavigationlist,
        getDynamicData : getDynamicData,
        getMasterData : getMasterData,
        saveContactFormdata : saveContactFormdata,
        getStaticPage : getStaticPage,
    }
}

export default Apis
