import { useEffect } from "react";
import AppRouter from "./routers/AppRouter";
import { useDispatch, useSelector } from "react-redux";
import MasterActions from "./redux/actions/MasterActions";
import ReactGA from "react-ga4";

function App() {
  const {getMasterDataList, getNavigationlistdata, getDynamicDataList, getSaticlistdata} = MasterActions()
  const dispatch = useDispatch()
  const {maindata} = useSelector((state) => state.masterdata);

  if (Object.keys(maindata).length > 0) {
    ReactGA.initialize(maindata.vendor.ga_id);
  }
  useEffect(()=>{
    dispatch(getMasterDataList())
    dispatch(getNavigationlistdata())
    dispatch(getDynamicDataList())
    dispatch(getSaticlistdata())
  },[])

  return (
    <AppRouter />
  );
}

export default App;
