import React from 'react'
import Apis from '../../services/Apis'
import { notify } from '../../components/common';

const MasterActions = () => {
  const {getNavigationlist, getDynamicData, getMasterData, saveContactFormdata, getStaticPage} = Apis();

  const getMasterDataList = () => async dispatch => {
    const response = await getMasterData()
    if(response.data.status == 'success'){
      dispatch({
        type: 'setmaindata',
        payload: response.data.data
      })
    }else{
      dispatch({
        type:'setmaindata',
        payload: []
      })
    }
  }

  const getNavigationlistdata = () => async dispatch => {
    const response = await getNavigationlist()
    
    if (response.data.status == 'success') {
      dispatch({
        type: 'setnavdata',
        payload: response.data.result,

      });
    } else {
      dispatch({
        type: 'setnavdata',
        payload: []
      });
    }
  }

  const saveContactFormdatalist = (input_data,setFormValues) => async dispatch => {
    dispatch({
      type: 'setloader',
      payload: true
    });
    const response = await saveContactFormdata(input_data);
    if (response.data.status == "success") {
      notify('', response.data.mesaage, false)
      
        document.getElementById("close-modal").click()
   
      setFormValues(
        {
          "name": "",
          "phone": "",
          "email": "",
          "subject": "",
          "message":"",
        }
      )
      dispatch({
        type: 'setloader',
        payload: false
      });
     
    } else {
      dispatch({
        type: 'loader',
        payload: false
      });
    }
  };

  const getDynamicDataList = () => async dispatch => {
    dispatch({
      type: 'setLoaderStatus',
      payload: true,
    });
    const response = await getDynamicData()
    
    if (response.data.status == 'success') {
      dispatch({
        type: 'setLoaderStatus',
        payload: false,
      });
      dispatch({
        type: 'setdynamicdata',
        payload: response.data.data,

      });
    } else {
      dispatch({
        type: 'setLoaderStatus',
        payload: false,
      });
      dispatch({
        type: 'setdynamicdata',
        payload: []
      });
    }
  }

  const getSaticlistdata = () => async dispatch => {
    const response = await getStaticPage()
    console.log("response", response)
    if (response.data.status == 'success') {
      dispatch({
        type: 'setstaticPage',
        payload: response.data.pagelist,

      });
    } else {
      dispatch({
        type: 'setnavdata',
        payload: []
      });
    }
  }

  return {
    getNavigationlistdata: getNavigationlistdata,
    getMasterDataList: getMasterDataList,
    saveContactFormdatalist: saveContactFormdatalist,
    getDynamicDataList: getDynamicDataList,
    getSaticlistdata: getSaticlistdata,
  }
}

export default MasterActions
