import React from 'react'
import img from "../../images/about-us.jpeg"
import { BsCheckLg  } from "react-icons/bs";

const ImageSideContent = () => {
    const data=[
        {
        "id": 1,
        "text" : "Market-Driven Implementation",
        },
        {
        "id": 2,
        "text" : "Data-Driven Decision Making",
        },
        {
        "id": 3,
        "text" : "Custom Software Development",
        },
        {
        "id": 4,
        "text" : "Agile and DevOps Methodologies",
        },
        {
        "id": 5,
        "text" : "Digital Strategy and Consulting",
        },
    ];
  return (
    <div>
        <div className="container">
            <div className="space-60"></div>
            <div className="space-40 d-none d-md-block"></div>
            <div className="row align-items-center">
                <div className="col-xl-5 col-lg-5 mb-5 mb-lg-0">
                    <div className="ot-heading">
                        <span className="is_line">About Company</span>
                        <h3 className="main-head">Partner with us to master challenges successfully </h3>
                    </div>
                    <div className="space-20"></div>
                    <p>We are a digital transformation and IT product development firm, delivering state-of-the-art engineering solutions to help businesses and enterprise clients tackle the complex challenges of their IT evolution journey.</p>
                    <div className="coporate-solution-box box-s1 icon-left bline-no">
                        {data.map((ele, i)=>{
                            return(
                                <div className="icon-box icon-box-2" key={i}>
                                    <div className="icon-main">
                                        <BsCheckLg  size={32} />
                                    </div>
                                    <div className="content-box">
                                        <h5 className="title-box">{ele.text}</h5>
                                    </div>
                                </div>
                            )
                        })  
                        }    
                    </div>
                </div>
                <div className="col-xl-7 col-lg-7">
                    <img src={img} alt="" className='img-fluid' style={{borderRadius: '10px'}}/>
                </div>
            </div>
            <div className="space-60"></div>
            <div className="space-60 d-none d-md-block"></div>
        </div>
    </div>
  )
}

export default ImageSideContent
