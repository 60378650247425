import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import LeftSideComponent from './LeftSideComponent'

const ServiceDetailComponent = ({data}) => {
   return (
    <>
        <div className='slider-detail-component'>
            <Container>
                <div className='space-90'></div>
                <Row>
                    <Col xl={9} lg={8} mb-5 mb-lg-0>
                        {
                            data.hasOwnProperty("body") &&
                            <div dangerouslySetInnerHTML={{ __html: data.body }}></div>
                        }
                    </Col>
                    <Col xl={3} lg={4} className='service-widget-area'>
                        <LeftSideComponent />
                    </Col>
                </Row>
            </Container>
        </div>
    </>
  )
}

export default ServiceDetailComponent
