import React from 'react'
import { FaPeopleGroup } from "react-icons/fa6";
import { AiOutlineCloudServer } from "react-icons/ai";
import { DiAndroid } from "react-icons/di";
import { IoApertureSharp } from "react-icons/io5";
import { FaGlobe, FaShareAlt } from "react-icons/fa";
import { MdOutlineContentPasteSearch } from "react-icons/md";
import { SiOpensourcehardware } from "react-icons/si";
import { BsRobot } from "react-icons/bs";
import { NavLink } from 'react-router-dom';

const Services = () => {
   
    return (
        <div className='services-section'>
            <div className="container">
                {/* <div className="space-60 d-none d-md-block"></div>
                <div className="space-60"></div> */}
                <div className="row">
                    <div className="col-lg-8 offset-lg-2 text-center ">
                        <div className="services-ot-heading">
                            <span className="pl-0">Our Services</span>
                            <h3 className="services-main-head">What We Offer</h3>
                        </div>
                        <div className="space-50"></div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6 mb-4">
                        <NavLink to={"/services/it-consulting"}>
                        <div className="hover-dark-yes coporate-service-box">
                            <div className="icon-box icon-box-1 text-center">
                                <div className="icon-main">
                                    <span className="circle-animate"></span>
                                    <FaPeopleGroup />
                                </div>
                                <div className="content-box">
                                    <h5 className="title-box">IT Consulting</h5>
                                    <p>Trust our top minds to eliminate workflow pain points, implement new technologies, and consolidate application portfolios.</p>
                                </div>
                            </div>
                        </div>
                        </NavLink>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <NavLink to={"/services/product-engineering"}>
                            <div className="hover-dark-yes coporate-service-box">
                                <div className="icon-box icon-box-1 text-center">
                                    <div className="icon-main">
                                        <span className="circle-animate"></span>
                                        <SiOpensourcehardware />
                                    </div>
                                    <div className="content-box">
                                        <h5 className="title-box">Product Engineering</h5>
                                        <p>Create complex enterprise software, ensure reliable software integration, modernise your legacy system.</p>
                                    </div>
                                </div>
                            </div>
                        </NavLink>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <NavLink to={"/services/web-apps"}>
                            <div className="hover-dark-yes coporate-service-box">
                                <div className="icon-box icon-box-1 text-center">
                                    <div className="icon-main">
                                        <span className="circle-animate"></span>
                                        <FaGlobe />
                                    </div>
                                    <div className="content-box">
                                        <h5 className="title-box">Web Applications</h5>
                                        <p>We develop customised responsive web applications that accentuates user experience and achive our customer goals.</p>
                                    </div>
                                </div>
                            </div>
                        </NavLink>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <NavLink to={"/services/mobile-applications"}>
                        <div className="hover-dark-yes coporate-service-box">
                            <div className="icon-box icon-box-1 text-center">
                                <div className="icon-main">
                                    <span className="circle-animate"></span>
                                    <DiAndroid />
                                </div>
                                <div className="content-box">
                                    <h5 className="title-box">Mobile Applications</h5>
                                    <p>We create impactful mobile apps that fits your brand and industry within a shorter time frame.</p>
                                </div>
                            </div>
                        </div>
                        </NavLink>
                        <div className="space-30 "></div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <NavLink to={"/services/digital-marketing"}>
                        <div className="hover-dark-yes coporate-service-box">
                            <div className="icon-box icon-box-1 text-center">
                                <div className="icon-main">
                                    <span className="circle-animate"></span>
                                    <FaShareAlt />
                                </div>
                                <div className="content-box">
                                    <h5 className="title-box">Digital Marketing</h5>
                                    <p>We deliver SEO, Social media advertising, campain management and SEM services to reach, nurture and convert the ideal customer across your online channels.</p>
                                </div>
                            </div>
                        </div>
                        </NavLink>
                        <div className="space-30 "></div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                    <NavLink to={"/services/ux-ui"}>
                        <div className="hover-dark-yes coporate-service-box">
                            <div className="icon-box icon-box-1 text-center">
                                <div className="icon-main">
                                    <span className="circle-animate"></span>
                                    <IoApertureSharp />
                                </div>
                                <div className="content-box">
                                    <h5 className="title-box">UX / UI Design</h5>
                                    <p>We build the product you need on time with an experienced team that uses a clear and effective design process.</p>
                                </div>
                            </div>
                        </div>
                        </NavLink>
                        <div className="space-30 "></div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                    <NavLink to={"/services/devops"}>
                        <div className="hover-dark-yes coporate-service-box">
                            <div className="icon-box icon-box-1 text-center">
                                <div className="icon-main">
                                    <span className="circle-animate"></span>
                                    <AiOutlineCloudServer />
                                </div>
                                <div className="content-box">
                                    <h5 className="title-box">DevOps</h5>
                                    <p>We help you attune the delivery, deployment, development, security, and support of any high-load, fail-safe system so your business strategy can always rely on high-quality software.</p>
                                </div>
                            </div>
                        </div>
                        </NavLink>
                        <div className="space-30 "></div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <NavLink to={"/services/ai-iot"}>
                            <div className="hover-dark-yes coporate-service-box">
                                <div className="icon-box icon-box-1 text-center">
                                    <div className="icon-main">
                                        <span className="circle-animate"></span>
                                        <BsRobot />
                                    </div>
                                    <div className="content-box">
                                        <h5 className="title-box">AI/IOT</h5>
                                        <p>We offers IoT services, enabling smart device integration, data collection, and real-time monitoring for improved operational efficiency and innovation.</p>
                                    </div>
                                </div>
                            </div>
                        </NavLink>
                        <div className="space-30 "></div>
                    </div>
                </div>
                <div className="space-60 d-none d-md-block"></div>
                <div className="space-60"></div>
            </div>
        </div>
    )
}

export default Services
