import React from 'react'

const Counter = () => {
    const img = "https://admin.shopersbay.com/assets/uploads/10015/ckeditor/488e9b80a7ab87869c9663d17fe6c532.jpg"
    return (
        <>
        <div className='container-fluid slider-detail-component'>
            <div className="service-detail-quote" style={{backgroundImage: `url(${img})`}}>
                <div className="service-detail-quote-desc">
                    <h3>We are Always Ready to Assist Our Clients</h3>
                    {/* <div class="space-20">&nbsp;</div> */}

                    <div className="ot-button"><a class="octf-btn octf-btn-light" data-bs-target="#exampleModal" data-bs-toggle="modal">get in touch</a></div>
                </div>
            </div>
        </div>
        <div className='space-60'></div>
        <div className='space-60 d-none d-md-block'></div>
        </>
    )
}

export default Counter
