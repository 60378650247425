import React from 'react'
import {Header, Footer, ReactTostify} from '../common'

const DesktopLayout = ({children}) => {
  return (
    <>
      <div className=" home-coporate">
        <div id="page" className="site">
          <Header/>
          {
            children
          }
          <Footer/>
          <ReactTostify />
        </div>
      </div>
    </>
  )
}

export default DesktopLayout