import React from 'react'
import ContactForm from './ContactForm'

const ContactComponent = () => {
    return (
        <>
        
        <div className="coporate-form">
            <div className="container">
            
                <div className="row">
                    <div className="col-lg-12">
                        <div className="coporate-form-wrap">
                            <div className="ot-heading">
                                <span className="is_line">TO GET STARTED</span>
                                <h3 className="main-head">Ready to<span className="hl-text"> Get Started?</span></h3>
                            </div>
                            <div className="space-20"></div>
                            <p>Please, fulfill the form to get a consultation</p>
                            <div className="space-10"></div>
                            <ContactForm />
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
        
        </>
    )
}

export default ContactComponent
